import { Bar } from 'vue-chartjs';
import { Component, Mixins, Prop } from 'vue-property-decorator';

@Component
export default class BarChart extends Mixins(Bar) {

    @Prop()
    public chartData!: any;

    @Prop()
    public options!: any;

    public mounted() {
        // Overwriting base render method with actual data.
        this.renderChart(this.chartData, this.options);
    }
}
