














import BarChart from '@/components/BarChart';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { format, startOfMonth } from 'date-fns';
import { dispatchGetMessagesByDay } from '@/store/admin/accessors';
import { IMessagesByDay } from '@/interfaces';

@Component({
  components: {
    BarChart,
  },
})
export default class Dashboard extends Vue {
  public isLoading: boolean = false;
  public showChart: boolean = false;
  public labels: string[] = [];
  public chartData: any = null;
  public options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            precision: 0,
          },
          stacked: true,
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },
  };
  public chartStyles = {
    height: `500px`,
    position: 'relative',
  };

  @Watch('showChart')
  public async loadChart(val) {
    if (!val) {
      return false;
    }
    this.isLoading = true;
    const data: IMessagesByDay[] = await this.getMessagesByDay();
    const deliveredData: number[] = [];
    const errorData: number[] = [];
    if (data) {
      for (const messageByDay of data) {
        this.labels.push(messageByDay.day);
        deliveredData.push(messageByDay.delivered_count);
        errorData.push(messageByDay.error_count);
      }
    }

    this.chartData = {
      labels: this.labels,
      datasets: [
        {
          label: 'Доставлено',
          backgroundColor: '#ccf8b3',
          data: deliveredData,
        },
        {
          label: 'Ошибки',
          backgroundColor: '#f87979',
          data: errorData,
        },
      ],
    };
    this.isLoading = false;
  }

  public async getMessagesByDay() {
    const today = new Date();
    return await dispatchGetMessagesByDay(this.$store, {
      from_date: format(startOfMonth(today), 'yyyy-MM-dd'),
      to_date: format(today, 'yyyy-MM-dd'),
    });
  }
}
